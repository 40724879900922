<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section" id="home">
        <div class="wrapper">
          <div class="title">PromptPerformerAi: Your Marketplace for AI Prompts</div>
          <div class="desc">
            <b>Unlock the Full Potential of AI with the Perfect Prompt</b>
          </div>
          <ul class="list">
            <li class="item desc">
              <b>High-Quality Prompts;</b>
            </li>
            <li class="item desc">
              <b>Expert-Reviewed;</b>
            </li>
            <li class="item desc">
              <b>Customizable;</b>
            </li>
          </ul>
          <router-link to="/product-list" class="button fill">Get Started</router-link>
        </div>
      </div>
      <div class="section model-section">
        <div class="wrapper">
          <div class="img-container">
            <img src="./../images/hero.jpg" class="img"/>
          </div>
          <div class="tab-list">
            <div v-for="item in modelOptions" :key="item.id" class='tab' @click="$emit('setActiveModel', item)">
              <img v-if="item.image_url" :src="$parent.imgDomain + item.image_url" class="img"/>
              <img v-else :class="['img', {'empty': !item.image_url}]"/>
              <span class="desc">
                <b>{{item.title}}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="section about-section" id="about">
        <div class="wrapper">
          <div class="title">About Us</div>
          <div class="content">
            <div class="desc">
              At PromptPerformerAi, we bridge the gap between human creativity and artificial intelligence. We offer a marketplace where you can buy prompts that help you get the most out of your AI models.
            </div>
            <router-link to="/product-list" class="button">Explore</router-link>
          </div>
        </div>
      </div>
      <div class="section categories-section" id="categories">
        <div class="wrapper">
          <div class="title">Categories</div>
          <div class="grid">
            <div class="item" 
            v-for="item in $parent.categories.filter(item => item.parent_id == 135).splice(0, 8)" 
            :key="item.id"
            @click="chooseCategory(item)"
            >
              <div class="desc">
                <b>{{ item.title }}</b>
              </div>
              <div class="img-container">
                <!-- <img src="./../images/hero.jpg" class="img"/> -->
                <img v-if="item.image_url" :src="$parent.imgDomain + item.image_url" class="img"/>
              </div>
            </div>
          </div>
          <router-link to="/product-list" class="button">Explore</router-link>
        </div>
      </div>

      <div class="section how-to-use-section" id="howToUse">
        <div class="wrapper">
          <div class="left">
            <div class="title">How to use?</div>
          </div>
          <div class="right">
            <div class="list">
              <img class="decor1 img" src="./../images/decor1.svg"/>
              <img class="decor2 img" src="./../images/decor2.svg"/>
              <img class="decor3 img" src="./../images/decor3.svg"/>
              <div class="item">
                <div class="text">
                  <div class="desc">
                    <b>Browse:</b>
                  </div>
                  <div class="desc">Search through our extensive library of expert-reviewed prompts.</div>
                </div>
                <div class="num">
                  <div class="title">01</div>
                </div>
              </div>
              <div class="item second">
                <div class="text">
                  <div class="desc">
                    <b>Select:</b>
                  </div>
                  <div class="desc">Choose the prompt that fits your needs.</div>
                </div>
                <div class="num">
                  <div class="title">02</div>
                </div>
              </div>
              <div class="item third">
                <div class="text">
                  <div class="desc">
                    <b>Purchase:</b>
                  </div>
                  <div class="desc">Complete your purchase securely.</div>
                </div>
                <div class="num">
                  <div class="title">03</div>
                </div>
              </div>
              <div class="item">
                <div class="text">
                  <div class="desc">
                    <b>Implement:</b>
                  </div>
                  <div class="desc">Use your new prompt with your AI model and watch it work like never before!</div>
                </div>
                <div class="num">
                  <div class="title">04</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section buy-sell-section">
        <div class="wrapper">
          <div class="title">The Marketplace</div>
          <div class="blocks">
            <div class="block">
              <div class="list">
                <div class="desc">Browse through a curated list of high-quality prompts</div>
                <div class="desc">Instant use after purchase</div>
                <div class="desc">24/7 Customer Support</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <div class="section contact-section">
        <div class="wrapper">
          <div class="cta">
            <div class="title">Ready to Turn Your AI into a Genius from Your AI Skills?</div>
            <router-link to="/product-list" class="button fill">Get Started</router-link>
          </div>
          <div class="title">Contact Us</div>
          <div class="text">
            <div class="desc">For any other questions, feel free to contact us</div>
            <button @click="$parent.openContactModal" class="button">Contact Us</button>
          </div>
        </div>
      </div>

      
    </div>
  </main>
</template>
<script>


export default {
  name: 'Home',
  props: ['currency', 'modelOptions', 'currencyOptions', 'currencyValue', 'models'],
  components: {
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  watch: {
    modelOptions: function(newVal) {
      this.$emit('setActiveSlider', newVal[0].id)
      this.$emit('getActiveModelProducts', newVal[0].id);
    },
    currencyOptions: function(newVal) {
      this.currencyOptions = newVal;
    },
    
  },
  mounted() {
    this.imgDomain = this.$parent.imgDomain;
    if (this.modelOptions.length) {
      this.$emit('setActiveSlider', this.modelOptions[0].id)
      this.$emit('getActiveModelProducts', this.modelOptions[0].id);
    }
  },
  methods: {
    chooseCategory(item) {
      this.$emit('chooseCategory', item)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    setStableDiffusionModel() {
      let model = this.models.find((obj) => obj.id === 135)
      this.$emit('setActiveModel', model);
    },
    setMidjourneyModel() {
      let model = this.models.find((obj) => obj.id === 136)
      this.$emit('setActiveModel', model);
    },
    setChatGptModel() {
      let model = this.models.find((obj) => obj.id === 134)
      this.$emit('setActiveModel', model);
    },
    setDalle2Model() {
      let model = this.models.find((obj) => obj.id === 137)
      this.$emit('setActiveModel', model);
    },
    setActiveSlider(id) {
      this.$emit('getActiveModelProducts', id)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
  }
}
</script>